import { useState, useEffect } from 'react';

// Função que verifica se o estabelecimento está aberto
const checkStoreStatus = () => {
  const now = new Date();
  const currentHour = now.getHours();
  
  // Verifica se o horário é antes das 18:00
  console.log(currentHour);
  
  return currentHour >= 12;
};

// Hook customizado para verificar e gerenciar o estado de abertura da loja
export const useStoreStatus = () => {
  const [isStoreOpen, setIsStoreOpen] = useState();
  const [msgStorageClose, setMsgStorageClose] = useState();

  useEffect(() => {
    const status = checkStoreStatus();
    setIsStoreOpen(status);
    
    if (isStoreOpen) {
        setMsgStorageClose(null);
    } else {
        setMsgStorageClose('Estabelecimento fechado. Abrimos somente após as 18:00.')
    }
  }, []);

  return { isStoreOpen, msgStorageClose };
};
