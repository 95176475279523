// MainContent.js

import React, { useEffect } from 'react';
import './MainContent.css';

const MainContent = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Quando o componente é montado, faz scroll para o topo da página
  }, []);

  return (
    <main className="main-content">
      {children}
    </main>
  );
};

export default MainContent;
