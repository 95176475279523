import React from 'react';
import { useNavigate} from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";

const Header = ({ titulo,tituloBotao,eventoBotao, routeGoBack }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(routeGoBack?routeGoBack:-1);
  };

  return (
    
      titulo ? (
        <>
          {/* Usar um fragmento para agrupar múltiplos elementos */}     
          <header style={styles.header}>
          <button onClick={goBack} style={styles.backButton}><FaArrowLeft size={30} /></button>
          <h1 style={styles.headerTitle}>{titulo}</h1>
          </header>
        </>
      ) : (      
        <>
          {/* Usar um fragmento para agrupar múltiplos elementos */}     
          <header style={styles.headerButton}>
          <button onClick={eventoBotao} style={styles.button}>{tituloBotao ? tituloBotao : "Clique para entrar"}</button>
          </header>
        </>
      )
      
      
    
  );
};

const styles = {
  headerButton: {
    display: 'flex',
    width: '100%',
    padding: '10px 0',
    backgroundColor: '#FF6F00',
    color: '#fff',
    textAlign: 'center',
    boxShadow: '0 4px 2px -2px gray',
    position: 'fixed',
    zIndex: 1000,  // Para garantir que o footer fique sempre por cima
    top: 0,
    left: 0,    
    justifyContent: 'space-around',

  },
  header: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',    
    backgroundColor: '#FF6F00',
    padding: '10px',
    color: '#fff',
    position: 'fixed',
    zIndex: 1000,  // Para garantir que o footer fique sempre por cima
    top: 0,
    left: 0,
    justifyContent: 'space-around',
  },
  headerTitle: {
    margin: '0 auto',
  },
  button: {
    backgroundColor: '#fff',
    color: '#FF6F00',
    border: '2px solid #FF6F00',
    padding: '10px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    alignItems: 'center',  
  },
  backButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
    fontSize: '18px',
    cursor: 'pointer',
  },  
};

export default Header;
