// src/components/Input/Input.js

import React from 'react';
import './Input.css'; // Arquivo CSS opcional para estilização

const Input  = ({ label, name, value, onChange, placeholder, required, error, type, readOnly }) => {
  
  return (
      <>
      {label && <label className="input-label">{label}</label>}
      <input 
        type={type ? type : "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder+(error ? ' *' : '')}        
        className={`input ${error && !value? 'input-error' : ''}`}
        required={required}
        readOnly={readOnly}
      />

      {/* Exibindo a mensagem de erro abaixo do input */}
      {error && <span className="input-error-message">{error}</span>}
      </>
      
  );
};

export default Input ;
