import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaChevronRight, FaCartArrowDown } from "react-icons/fa";
import { BsShopWindow } from "react-icons/bs";
import { MdDeliveryDining } from "react-icons/md";
import { fetchData } from '../../services/Api';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import './Endereco.css';  // Importar o arquivo CSS externo.
import MainContent from '../../components/Form/MainContent';
import { formatCurrency } from '../../utils/numberUtils';
import Modal from '../../components/Modal/Modal'; // Importa o Modal
import ModalInput from '../../components/Modal/ModalInput';
import { useAuth } from '../../contexts/AuthContext'; // Importando o hook useAuth

const Endereco = () => {
  const [totalItem, setTotalItem] = useState(0);
  const [taxaEntrega, setTaxaEntrega] = useState(0);
  const [endereco, setEndereco] = useState([]);
  const [enderecoCliente, setEnderecoCliente] = useState('');
  const [showModalInput, setShowModalInput] = useState(false); // Estado para o modal de endereço
  const [tiposEntrega, setTiposEntrega] = useState([]);
  const [tipoEntrega, setTipoEntrega] = useState(null);
  const [modalMessage, setModalMessage] = useState(''); // Estado para o modal
  const navigate = useNavigate();  
  const { isAuthenticated } = useAuth(); // Usando o contexto para verificar autenticação

  useEffect(() => {
    
    const fetchDataFromAPI = async () => {      
      try {        
        setTotalItem(parseFloat(localStorage.getItem('totalItem')));
        if (isAuthenticated) {
          const result = await fetchData('/Endereco');
          if (result!=null) {
            setEndereco(result);
          }            
        }
      } catch (error) {
        console.log("Erro ao buscar cliente:" + error);
      }      
    };
    

    const fetchTipoEntrega = async () => {
      try {
        const result = await fetchData('/TipoEntregaDelivery');
        setTiposEntrega(result);

        const tipoEnt = JSON.parse(localStorage.getItem('tipoEntrega'));
        console.log(tipoEnt);
        
        if (tipoEnt)        
          setTipoEntrega(tipoEnt);
        
      } catch (error) {
        console.log("Erro ao buscar tipos de entrega:" + error);
      }
    };

    fetchDataFromAPI();
    fetchTipoEntrega();
  }, []);

  useEffect(() => {
    if (enderecoCliente!=='')
    {
      handleCheckout();
    }
  }, [enderecoCliente]);

  const handleCadastrarEndereco = () => {    
    if (!isAuthenticated) {
      setShowModalInput(true); // Exibe o modal para clientes não autenticados
    } else {
      navigate('/cadastrar-endereco', { state: { endereco } });
    }
  };

  const handleSaveEndereco = (novoEndereco) => {
    setEnderecoCliente(novoEndereco);
    localStorage.setItem('enderecoCliente', novoEndereco);
  };

  const handleEntregaClick = (tipo) => {
    setTipoEntrega(tipo);
    setTaxaEntrega(tipo.taxaEntrega);
  };

  const handleCheckout = () => {
    console.log('teste check endereco');
    
    if (!tipoEntrega) {
      setModalMessage('Por favor, selecione um tipo de entrega.'); // Exibe o modal
      return;
    }

    localStorage.setItem('tipoEntrega', JSON.stringify(tipoEntrega));

    if (tipoEntrega && tipoEntrega.descricao !== "Retirada") {
      if (isAuthenticated) {
        if (!endereco || endereco.length === 0) {
          setModalMessage('Por favor, cadastre um endereço primeiro.'); // Exibe o modal
          return; 
        }
      } else {
        if (!enderecoCliente || enderecoCliente.length === 0) {
          //setModalMessage('Por favor, informe um endereço primeiro.'); // Exibe o modal
          handleCadastrarEndereco();
          if (!enderecoCliente)
          return;
        }
      }
    }

    if (tipoEntrega && tipoEntrega.descricao !== "Retirada") {
      // só grava o endereço se for retirada      
      if (isAuthenticated) {
        localStorage.setItem('endereco', JSON.stringify(endereco)); // com login endereço da base    
      }
    }
    navigate('/pagamento');
  };

  const closeModal = () => setModalMessage(''); // Fecha o modal

  const isCheckoutDisabled = !tipoEntrega; // Habilitação do botão de checkout

  return (
    <>
      <Header titulo="Finalizar Pedido" />
      <MainContent>
        <div className="entrega-buttons-container">
          {tiposEntrega.map((tipo) => (
            <button
              key={tipo.id}
              className={`tipoEntrega-button ${tipoEntrega?.id === tipo.id ? 'disabled' : ''}`}
              onClick={() => handleEntregaClick(tipo)}
              disabled={tipoEntrega?.id === tipo.id} // Desabilita apenas o botão selecionado
            >
              {tipo.descricao} {tipo.descricao==="Delivery" ? (<MdDeliveryDining/>):(<BsShopWindow/>)} 
            </button>
          ))}
        </div>

        {/* Endereço */}
        {(tipoEntrega && tipoEntrega.descricao !== "Retirada") && (
          <div>
            <h3>Endereço</h3>
            <div key={endereco.id} className="container" onClick={handleCadastrarEndereco}>
              <div id="enderecoIcone">
                <HiOutlineLocationMarker color={endereco.id ? '#505050' : '#ffd901'} size={40} />
              </div>
              {endereco.id ? (
                <div className="enderecoContainer">
                  <p className="enderecoItemTitulo">{endereco.logradouro}, {endereco.numero}</p>
                  <p>Bairro {endereco.bairro}</p>
                  <p>{endereco.cidade?.nome}/RS</p>
                  <p className="enderecoItemComplemento">{endereco.complemento}</p>
                </div>
              ) : (
                <p className="enderecoItemTitulo">{isAuthenticated ? "Cadastrar endereço" : enderecoCliente ? enderecoCliente: "Informar endereço"}</p>
              )}
              <div id="enderecoIcone">
                <FaChevronRight size={20} />
              </div>
            </div>
          </div>
        )}

        <div className="total-container">
          <div className="total-linha">
            <p className="total-second">Pedido: </p>
            <p className="total-second">{formatCurrency(totalItem)}</p>
          </div>
          {taxaEntrega > 0 && (
          <div className="total-linha">
            <p className="total-second">Taxa de Entrega: </p>
            <p className="total-second">{formatCurrency(taxaEntrega)}</p>
          </div>
          )}
          <div className="total-linha">
            <p className="total-title">Total a Pagar: </p>
            <p className="total-title">{formatCurrency(totalItem + taxaEntrega)}</p>
          </div>
          <button
            className="checkout-button"
            disabled={isCheckoutDisabled} // Desabilita se pagamento ou entrega não estiverem selecionados
            onClick={handleCheckout}
          >
            Ir para Pagamento <FaCartArrowDown />
          </button>
        </div>
      </MainContent>

      <Footer />

      <Modal 
        show={!!modalMessage} 
        onClose={closeModal} 
        message={modalMessage} 
      />
      <ModalInput
        show={showModalInput}
        onClose={() => setShowModalInput(false)}
        onSave={handleSaveEndereco}
        titulo={"Informe seu Endereço:"}
        placeholder={"Digite seu Endereço"}
      />
    </>
  );
};

export default Endereco;
