import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHamburger, FaPizzaSlice, FaBeer, FaGlassMartiniAlt } from 'react-icons/fa'; // Importação de ícones representativos
import { GiPisaTower, GiWaterBottle, GiCarWheel } from "react-icons/gi";
import { FaBowlFood } from "react-icons/fa6";
import { PiBowlFoodLight } from "react-icons/pi";
import MainContent from '../components/Form/MainContent';
import Header from '../components/Form/Header';
import Footer from '../components/Form/Footer';
import { fetchData } from '../services/Api';

import './OrderMenu.css';

const OrderMenu = () => {
  const [groups, setGroups] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        const result = await fetchData('/GrupoDelivery');
        setGroups(result);
      } catch (error) {
        console.error("Erro ao buscar grupos:", error);
      }
    };
    fetchDataFromAPI();
  }, []);

  const goToMenu = (group) => {
    console.log(group);
    
    if (group.temSabor===1)
    {
      navigate(`/tipoPizza/${group.id}`);
    }
    else{
    navigate(`/beermenu/${group.id}`);
    }
  };

  const getIcon = (descricao) => {
    switch (descricao.toLowerCase()) {
      case 'x médio':
        return <FaHamburger style={{ marginRight: '10px' }} />;
      case 'x calota':
        return <GiCarWheel style={{ marginRight: '10px' }} />;
      case 'pizza':
        return <FaPizzaSlice style={{ marginRight: '10px' }} />;
      case 'cervejas':
        return <FaBeer style={{ marginRight: '10px' }} />;
      case 'drinks':
        return <FaGlassMartiniAlt style={{ marginRight: '10px' }} />;
      case 'torres' || 'torre':
        return <GiPisaTower style={{ marginRight: '10px' }} />;
      case 'porções':
        return <FaBowlFood style={{ marginRight: '10px' }} />;
      case 'água/refrigerante':
        return <GiWaterBottle style={{ marginRight: '10px' }} />;
      case 'vianda':
          return <PiBowlFoodLight style={{ marginRight: '10px' }} />;        
      default:
        return null;
    }
  };

  return (
    <>
      <Header titulo="Menu" />
      <MainContent>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '20px',
            padding: '10px',
          }}
        >
          {groups.map((group) => (
            <button
              key={group.id}
              className="menu-button"
              onClick={() => goToMenu(group)}
            >
              {group.descricao} {getIcon(group.descricao)}              
            </button>
          ))}
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default OrderMenu;
