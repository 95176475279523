import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../services/Api";
import { formatCurrency } from "../../utils/numberUtils";
import Header from "../../components/Form/Header";
import MainContent from "../../components/Form/MainContent";
import Footer from "../../components/Form/Footer";
import './TipoPizza.css';  // Importar o arquivo CSS externo.

const TipoPizza = () => {
  const { groupId } = useParams(); // Obtém o parâmetro da URL
  const navigate = useNavigate();
  const [pizzas, setPizzas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupName, setGroupName] = useState("Pizzas"); // Título padrão

  useEffect(() => {
    // Função para buscar dados da API
    const fetchDataFromAPI = async () => {
      try {
        const result = await fetchData('/ProdutoDelivery/ProdutosByGrupo', 
          { grupoId: groupId } // Passa o grupoId como parâmetro
        );

        // Ajuste o estado dos dados recebidos da API
        setPizzas(result);
        setLoading(false);
      } catch (error) {
        setError("Erro ao buscar produtos: " + error);
        setLoading(false);
      }
    };

    fetchDataFromAPI();
  }, [groupId]);

  useEffect(() => {
    // Verifique se temos pelo menos uma pizza para pegar o grupo
    if (pizzas.length > 0 && pizzas[0].grupo) {
      setGroupName(pizzas[0].grupo.descricao); // Pega o grupo da primeira pizza
    }
  }, [pizzas]); // Depende da lista de pizzas

  const handlePizzaClick = (pizza) => {
    navigate(`/pizzaDetails/${pizza.id}/${pizza.grupo.id}`, { state: { pizza } });
  };
  
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error}</p>;

  return (
    <>
      <Header titulo={groupName} /> {/* Altere para usar o nome do grupo que foi definido */}

      <MainContent>
        {pizzas.map((pizza) => (
          <div key={pizza.id} className="pizzaLink" onClick={() => handlePizzaClick(pizza)}>
            <div className="pizzaItem">
              
              <div className="pizzaInfo">
                <h2 className="pizzaName">{pizza.descricao}</h2>
                <p className="pizzaVariation">{pizza.variacao.descricao}</p>
                <p className="pizzaPrice">{formatCurrency(pizza.valor)}</p>
              </div>
              <img src={`data:image/jpeg;base64,${pizza.imagem.arquivo}`} alt={pizza.descricao} className="pizzaImg" />
            </div>
          </div>
        ))}
      </MainContent>

      <Footer />
    </>
  );
};

export default TipoPizza;