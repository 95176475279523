import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../services/Api';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import { FaArrowRightToBracket } from "react-icons/fa6";
import './Pedidos.css'; 

const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const result = await fetchData('/PedidoDelivery/GetByClienteId'); // Passa o clienteId como parâmetro
        // Inverter a ordem dos pedidos
        setPedidos(result.reverse());
      } catch (error) {
        console.error('Erro ao buscar pedidos:', error);
      }
    };

    fetchPedidos();
  }, []);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString("pt-BR", options);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Enviado':
        return 'status-pendente';
      case 'Cancelado':
        return 'status-cancelado';
      case 'Concluído':
        return 'status-concluido';
      default:
        return '';
    }
  };

  const handleIconClick = (pedido) => {
    navigate(`/resumo-pedido/${pedido.id}`, { state: { pedido } });
  };

  return (
    <>
      <Header titulo="Meus Pedidos" routeGoBack="/" />
      <div className="pedidos-container">
        {pedidos.length === 0 ? (
          <p className="empty-message">Nenhum pedido encontrado.</p>
        ) : (
          pedidos.map((pedido) => (
            <div key={pedido.id} className="pedido-item">

              <div className="pedido-itemInfo">                                          
                <div className={`pedido-status ${getStatusClass(pedido.statusPedido.descricao)}`}>
                  {pedido.statusPedido.descricao}
                </div>
                {pedido.statusPedido.descricao === 'Enviado' && <p>Aguardando aprovação</p>}
                
                <p className="pedido-number"><strong>Pedido #{pedido.id}</strong></p>
                <p className="pedido-date">Realizado em: {formatDate(pedido.dataHoraPedido)}</p>
              </div>
              <FaArrowRightToBracket 
                size={60} 
                className="icone-detalhes"
                onClick={() => handleIconClick(pedido)}
              />
            </div>
          ))
        )}
      </div>
      <Footer />
    </>
  );
};

export default Pedidos;
