// src/components/Form/ModalInput.js
import React, { useState } from 'react';
import './ModalInput.css'; // Estilos para o modal de input

const ModalInput = ({ show, onClose, onSave, titulo, placeholder }) => {
  const [textoInput, setTextoInput] = useState('');

  if (!show) {
    return null;
  }

  const handleSave = () => {
    if (textoInput.trim() !== '') {
      onSave(textoInput);
      onClose();
    }
  };

  return (
    <div className="modal-texto-overlay">
      <div className="modal-texto-content">
        <h2>{titulo?titulo:"Informe seu texto"}</h2>
        <input
          type="text"
          value={textoInput}
          onChange={(e) => setTextoInput(e.target.value)}
          placeholder={placeholder?placeholder:"Digite seu texto"}
          className="modal-texto-input"
        />
        <div className="modal-texto-buttons">          
          <button className="modal-texto-button" onClick={onClose}>
            Cancelar
          </button>
          <button className="modal-texto-button" onClick={handleSave}>
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalInput;
