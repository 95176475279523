// src/contexts/AuthContext.js
import React, { createContext, useState, useContext } from 'react';

// Criação do contexto
const AuthContext = createContext();

// Componente Provedor
export const AuthProvider = ({ children }) => {
  //seta como true se tiver o token preenchido
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') ? true : false);

  const logout = () => {
    localStorage.removeItem('token'); // Remove o token do localStorage
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personalizado para usar o contexto
export const useAuth = () => {
  return useContext(AuthContext);
};
