import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../services/Api";
import Header from "../components/Form/Header";
import MainContent from "../components/Form/MainContent";
import Footer from "../components/Form/Footer";
import './BeerMenu.css';  // Importar o arquivo CSS externo.
import { formatCurrency } from "../utils/numberUtils";

const BeerMenu = () => {
  const { groupId } = useParams(); // Obtém o parâmetro da URL
  const navigate = useNavigate();
  const [beers, setBeers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupName, setGroupName] = useState("Cervejas"); // Título padrão

  useEffect(() => {
    // Função para buscar dados da API
    const fetchDataFromAPI = async () => {
      try {
        console.log(groupId);
        
        const result = await fetchData('/ProdutoDelivery/ProdutosByGrupo', 
          { grupoId: groupId } // Passa o grupoId como parâmetro
        );

        // Ajuste o estado dos dados recebidos da API
        setBeers(result);
        setLoading(false);
      } catch (error) {
        setError("Erro ao buscar produtos: " + error);
        setLoading(false);
      }
    };

    fetchDataFromAPI();
  }, [groupId]);

  useEffect(() => {
    // Verifique se temos pelo menos uma cerveja para pegar o grupo
    if (beers.length > 0 && beers[0].grupo) {
      setGroupName(beers[0].grupo.descricao); // Pega o grupo da primeira cerveja
    }
  }, [beers]); // Depende da lista de cervejas

  const handleBeerClick = (beer) => {
    navigate(`/drinkdetails/${beer.id}`, { state: { beer } });
  };
  
  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error}</p>;

  return (
    <>
      <Header titulo={groupName} /> {/* Altere para usar o nome do grupo que foi definido */}

      <MainContent>
        {beers.map((beer) => (
          <div key={beer.id} className="beerLink" onClick={() => handleBeerClick(beer)}>
            <div className="beerItem">
              <img src={`data:image/jpeg;base64,${beer.imagem.arquivo}`} alt={beer.descricao} className="beerImg" />
              <div className="beerInfo">
                <h2 className="beerName">{beer.descricao}</h2>
                <p className="beerSize">{beer.ingredientes ? beer.ingredientes : beer.variacao.descricao}</p>
                <p className="beerPrice">{formatCurrency(beer.valor)}</p>
              </div>
            </div>
          </div>
        ))}
      </MainContent>

      <Footer />
    </>
  );
};

export default BeerMenu;
