import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCartArrowDown, FaMoneyBill, FaCreditCard } from "react-icons/fa";
import { MdOutlinePix } from "react-icons/md";
import {fetchData, postData } from '../../services/Api';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import './Endereco.css';  // Importar o arquivo CSS externo.
import MainContent from '../../components/Form/MainContent';
import { formatCurrency } from '../../utils/numberUtils';
import Modal from '../../components/Modal/Modal'; // Importa o Modal
import { useAuth } from '../../contexts/AuthContext';
import { handleEnviarPedido } from '../../components/handleEnviarPedido';

const Pagamento = () => {
  const [totalItem, setTotalItem] = useState(0);
  const [taxaEntrega, setTaxaEntrega] = useState(0);
  const [endereco, setEndereco] = useState(null);
  const [enderecoCliente, setEnderecoCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState(null);
  const [tipoEntrega, setTipoEntrega] = useState(null);
  const [pagamento, setPagamento] = useState(null);
  const [modalMessage, setModalMessage] = useState(''); // Estado para o modal
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth(); // Usando o contexto para verificar autenticação

  useEffect(() => {

    if (!isAuthenticated)
    {
      setEnderecoCliente(localStorage.getItem('enderecoCliente'));
      setNomeCliente("Júnior Teste");
    }
    
    const enderecoData = JSON.parse(localStorage.getItem('endereco'));
    const tipoEntregaData = JSON.parse(localStorage.getItem('tipoEntrega'));

    if (enderecoData!==null)
    {
      setEndereco(enderecoData);
    }
    
    setTipoEntrega(tipoEntregaData);
    setTotalItem(parseFloat(localStorage.getItem('totalItem')));

    if (tipoEntregaData && tipoEntregaData.taxaEntrega) {
      setTaxaEntrega(tipoEntregaData.taxaEntrega);
    }
  }, []);

  const PaymentMethods = {
    DINHEIRO: 1,
    PIX: 2,
    CARTAO: 3,
  };

  const PaymentMethodsDescription = {
    1: 'Dinheiro',
    2: 'Pix',
    3: 'Cartão',
  };

  const handleButtonClick = (buttonType) => {
    setPagamento(buttonType); 
  };



  const handleCheckout = async () => {
    //if (endereco.length === 0) {
    //  setModalMessage('Por favor, cadastre um endereço primeiro.');
    //  return;
    //}

    if (!pagamento) {
      setModalMessage('Por favor, selecione uma forma de pagamento.');
      return;
    }
    

    localStorage.setItem('formaPagamento', pagamento);

    const cartData = JSON.parse(localStorage.getItem('cart'));
    if (cartData && Array.isArray(cartData)) {
      var itensPedido = cartData.map(item => ({
        produto: { id: item.id },
        qtde: item.quantity, 
        valor: item.valor*item.quantity, 
        observacao: item.observation,
        // Incorporar sabores nos itens do pedido, certificando-se de que selectedFlavors existe e é um array
        pedidoItemSabores: item.selectedFlavors ? item.selectedFlavors.map(sabor => ({
          sabor: { id: sabor.id },
          qtde: 1, // ou outro cálculo de quantidade como `item.quantity` ou propriedade dentro do `sabor`
        })) : []  // Retorna array vazio se selectedFlavors não está definido
      }));
      console.log('Itens Pedido:', itensPedido);
    }

    

    try {
      const pedido = {
        statusPedido: { id: 1, descricao: "Realizado" },
        cliente: { id: 1 },
        nomeCliente:nomeCliente,
        enderecoCliente:enderecoCliente,
        tipoEntrega: { id: tipoEntrega ? tipoEntrega.id : null, taxaEntrega: taxaEntrega },
        tipoPagamento: { id: pagamento },
        valorItens: totalItem,
        taxaEntrega: taxaEntrega,
        valorTotal: totalItem + taxaEntrega,
        endereco,
        dataHoraPrevisao: null,
        pagamento: { id: pagamento },       
        pedidoItens: itensPedido,  
      };

      console.log('PedidoPost:',pedido);
      let response;
      if (isAuthenticated)
      {
        response = await postData('/PedidoDelivery', pedido);
      } else {
        response = await postData('/PedidoSemLoginDelivery', pedido);
      }
      
      const result = await fetchData('/PedidoDelivery', { id: response.id}); // Passa o id do pedido como parâmetro
    
      const whatsappUrl = handleEnviarPedido(result); // Chame a função externa
      window.location.href = whatsappUrl; // Usa location.href para não abrir nova aba
      
      localStorage.removeItem('cart');
      localStorage.removeItem('tipoEntrega');
      localStorage.removeItem('formaPagamento');  
      setTotalItem(0); 

      if (isAuthenticated) {
        navigate('/resumo-pedido/' + response.id);
      } else {
        navigate('/');
      }
      

    } catch (error) {
      console.error('Erro: ', error);
    }
  }

  const closeModal = () => setModalMessage(''); // Fecha o modal

  const isCheckoutDisabled = !pagamento; // Habilitação do botão de checkout

  return (
    <>
      <Header titulo="Finalizar Pedido" />
      <MainContent>        

        {/* Forma de Pagamento */}  
        <h3>Forma de Pagamento</h3>
        <div className="payment-buttons-container">
          <button
            className={`pagButton ${pagamento === PaymentMethods.DINHEIRO ? 'selected' : ''}`}
            onClick={() => handleButtonClick(PaymentMethods.DINHEIRO)}
          >
            <FaMoneyBill size={40} /> {PaymentMethodsDescription[PaymentMethods.DINHEIRO]}
          </button>
          <button
            className={`pagButton ${pagamento === PaymentMethods.PIX ? 'selected' : ''}`}
            onClick={() => handleButtonClick(PaymentMethods.PIX)}
          >
            <MdOutlinePix size={40} /> {PaymentMethodsDescription[PaymentMethods.PIX]}
          </button>
          <button
            className={`pagButton ${pagamento === PaymentMethods.CARTAO ? 'selected' : ''}`}
            onClick={() => handleButtonClick(PaymentMethods.CARTAO)}
          >
            <FaCreditCard size={40} /> {PaymentMethodsDescription[PaymentMethods.CARTAO]}
          </button>
        </div>

        <div className="total-container">
          <div className="total-linha">
            <p className="total-second">Pedido: </p>
            <p className="total-second">{formatCurrency(totalItem)}</p>
          </div>
          <div className="total-linha">
            <p className="total-second">Taxa de Entrega: </p>
            <p className="total-second">{formatCurrency(taxaEntrega)}</p>
          </div>
          <div className="total-linha">
            <p className="total-title">Total a Pagar: </p>
            <p className="total-title">{formatCurrency((totalItem + taxaEntrega))}</p>
          </div>
          <button
            className="checkout-button"
            disabled={isCheckoutDisabled} // Desabilita se pagamento ou entrega não estiverem selecionados
            onClick={handleCheckout}
          >
            Confirmar Pedido <FaCartArrowDown />
          </button>

          
        </div>        
      </MainContent>

      <Modal 
        show={!!modalMessage} 
        onClose={closeModal} 
        message={modalMessage} 
      />
      <Footer />
    </>
  );
};

export default Pagamento;
