import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';

const Resumo = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [endereco, setEndereco] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartItems);

    const enderecoSelecionado = localStorage.getItem('endereco');
    setEndereco(enderecoSelecionado);

    const formaPagamentoSelecionada = localStorage.getItem('formaPagamento');
    setFormaPagamento(formaPagamentoSelecionada);
  }, []);

  const totalAmount = cart.reduce((sum, item) => sum + item.valor * item.quantity, 0);

  const handleConcluirPedido = () => {
    // Limpar storage e redirecionar para a página inicial ou mostrar uma mensagem de confirmação
    localStorage.removeItem('cart');
    localStorage.removeItem('endereco');
    localStorage.removeItem('formaPagamento');
    alert('Pedido concluído com sucesso!');
    navigate('/');
  };

  return (
    <>
      <Header titulo="Resumo do Pedido" />
      <div style={styles.container}>
        <h3>Produtos</h3>
        {cart.map((item, index) => (
          <div key={index} style={styles.item}>
            <p>{item.descricao} (x{item.quantity}) - R$ {(item.valor * item.quantity).toFixed(2)}</p>
          </div>
        ))}
        <h3>Endereço</h3>
        <p>{endereco}</p>
        <h3>Forma de Pagamento</h3>
        <p>{formaPagamento}</p>
        <h3>Total: R$ {totalAmount.toFixed(2)}</h3>
        <button onClick={handleConcluirPedido} style={styles.button}>Concluir Pedido</button>
      </div>
      <Footer />
    </>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  item: {
    padding: '10px',
    margin: '10px 0',
    backgroundColor: '#f5f5f5',
    borderRadius: '5px',
  },
  button: {
    backgroundColor: '#FF6F00',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '20px',
  },
};

export default Resumo;
