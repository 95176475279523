import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import { FaPlus, FaTrashAlt, FaCheck } from 'react-icons/fa'; // Importando ícones
import { BsWhatsapp } from "react-icons/bs"; // Ícone do WhatsApp
import './Cart.css';  // Importar o arquivo CSS externo.
import { formatCurrency } from '../../utils/numberUtils';
import { useStoreStatus } from '../../contexts/useStoreStatus';
import Modal from '../../components/Modal/Modal';

const Cart = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const { isStoreOpen, msgStorageClose } = useStoreStatus();
  
  const whatsappNumber = "+555596333319"; // Número do estabelecimento no formato internacional
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=Olá, gostaria de saber sobre o funcionamento do estabelecimento.`;

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartItems);
  }, []);

  const removeItem = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    window.dispatchEvent(new Event('storage'));
  };

  const totalAmount = cart.reduce((sum, item) => sum + item.valor * item.quantity, 0);

  const handleCheckout = () => {
    if (isStoreOpen) {
      localStorage.setItem('totalItem', JSON.stringify(totalAmount));
      navigate('/endereco');      
    } else {
      setModalIsOpen(true);      
    }
  };

  const chooseAnotherItem = () => {
    navigate('/ordermenu'); // Ajuste o caminho para a página de produtos conforme necessário
  };

  return (
    <>
      <Header titulo="Carrinho" />
      <div className="cart-container">
        {cart.length === 0 ? (
          <p className="empty-cart-message">O carrinho está vazio.</p>
        ) : (
          <>
            {cart.map((item, index) => (
              <div key={item.id} className="cart-item">
                <p className="item-description">
                  <strong>{item.descricao}</strong>
                  <span className="item-variation"> {item.variacao.descricao}</span>
                </p>
                <div className="item-data">
                  <img src={`data:image/jpeg;base64,${item.imagem.arquivo}`} alt={item.descricao} className="image"/>
                  <div className="item-info">
                    <p className="item-info-text"><strong>Quantidade:</strong> {item.quantity}</p>
                    <p className="item-info-text"><strong>Val. Un:</strong> {formatCurrency(item.valor)}</p>
                    <p className="item-info-text"><strong>Total:</strong> {formatCurrency((item.valor * item.quantity))}</p>
                  </div>
                  <button onClick={() => removeItem(index)} className="remove-button">
                    <FaTrashAlt /> Excluir
                  </button>
                </div>
              </div>
            ))}
            <div className="total-container">
              <h3 className="total-title">Total do Pedido: R$ {totalAmount.toFixed(2)}</h3>
              <button onClick={chooseAnotherItem} className="choose-another-button">
                <FaPlus /> Escolher Outro Item
              </button>
              <button onClick={handleCheckout} className="checkout-button">
                <FaCheck /> Finalizar Pedido
              </button>
            </div>
          </>
        )}
      </div>
      <Footer />
      <Modal 
        show={modalIsOpen} 
        onClose={() => setModalIsOpen(false)} 
        message={
          <>
            <p>{msgStorageClose}</p>
            
            <a 
              href={whatsappLink} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="whatsapp-button"
            >
              <BsWhatsapp className="whatsapp-icon" /> Contatar via WhatsApp
            </a>
          </>
        } 
      />
    </>
  );
};

export default Cart;
