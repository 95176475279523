import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from 'react-modal';
import { useAuth } from '../contexts/AuthContext'; // Importando o hook useAuth
import { useStoreStatus } from '../contexts/useStoreStatus';

import Header from "../components/Form/Header";
import Footer from "../components/Form/Footer";
import MainContent from "../components/Form/MainContent";

Modal.setAppElement('#root'); // Necessário para acessibilidade

function Home() {
  const { isAuthenticated } = useAuth(); // Usando o contexto para verificar autenticação
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { isStoreOpen, msgStorageClose } = useStoreStatus();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const navigate = useNavigate();

  const goToOrderMenu = () => {
    navigate('/ordermenu');
  };

  const goToLogin = () => {
    if (isAuthenticated) {
      navigate('/perfilusuario');
    } else {
      navigate('/login');
    }
  }; 

  return (
    <>
      <Header tituloBotao={isAuthenticated ? "Meu Cadastro" : "Clique para entrar"} eventoBotao={goToLogin} />
      <MainContent>
        <img src="/images/LogoCia.png" alt="Imagem Azul" style={styles.image} />
        
        <button style={styles.button} onClick={goToOrderMenu}>
          Fazer Pedido
        </button>
        
        {/* Exibe a mensagem se a loja estiver fechada */}
        {!isStoreOpen && (
          <p style={styles.closedMessage}>
            {msgStorageClose}
          </p>
          
        )}
        
        <button style={styles.infoButton} onClick={openModal}>
          <FaInfoCircle style={styles.icon} />
          Horários de Atendimento
        </button>
        
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Horários de Atendimento"
          style={customStyles}
        >
          <h2 style={styles.modalTitle}>Horários de Atendimento</h2>
          <p style={styles.modalParagraph}>
            Segunda a sábado das <span style={styles.hours}>18:30</span> às <span style={styles.hours}>22:30</span>
          </p>
          <p style={styles.modalParagraph}>
            Domingos <span style={styles.hours}>18:30</span> às <span style={styles.hours}>22:00</span>
          </p>
          <button onClick={closeModal} style={styles.modalButton}>Fechar</button>
        </Modal>
      </MainContent>
      <Footer />
    </>
  );
}

const styles = {
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: '#FF6F00',
    color: '#fff',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '25px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '20px',
    opacity: '1',
  },
  infoButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '25px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '10px',
  },
  closedMessage: {
    color: 'red',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  modalTitle: {
    marginBottom: '20px',
  },
  modalParagraph: {
    marginBottom: '10px',
  },
  modalButton: {
    backgroundColor: '#FF6F00',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  hours: {
    fontWeight: 'bold',
    color: '#333',
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    textAlign: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

export default Home;
