// src/pages/Login.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

import Header from "../components/Form/Header";
import Footer from "../components/Form/Footer";
import MainContent from "../components/Form/MainContent";
import './Login.css';
import Modal from '../components/Modal/Modal';


function Login() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

const location = useLocation();
const redirectTo = location.state?.from?.pathname || '/perfilusuario';

const handleLogin = async () => {
  try {
    const baseURL = window.APP_CONFIG?.Url_Api || 'http://default-url.com';
    const response = await axios.post(baseURL + '/loginApp', {
      email: email,
      senha: password
    });

    localStorage.setItem('token', response.data.token);
    setIsAuthenticated(true);
    navigate(redirectTo); // Redirecionar para a página anterior ou uma rota padrão
  } catch (error) {
    setErrorMessage(error.response?.data?.message || 'Erro ao fazer login');
    setModalIsOpen(true);
  }
};

  const handleCreateAccount = () => {
    navigate('/cadastrarusuario');
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <>
      <Header titulo={"Login"} />
      <MainContent>
        <div className="main">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="inputLogin"
          />
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="inputLogin"
          />
          <button onClick={handleLogin} className="buttonEntrar">
            Entrar
          </button>
          <p>
            <a onClick={handleForgotPassword} className="link">
              Esqueci Minha Senha
            </a>
          </p>
          <button onClick={handleCreateAccount} className="createAccountButton">
            Criar Conta
          </button>
        </div>
      </MainContent>
      <Footer />

      {/* Passando as props corretas para o Modal */}
      <Modal show={modalIsOpen} onClose={() => setModalIsOpen(false)} message={errorMessage} />
    </>
  );
}

export default Login;
