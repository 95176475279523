import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { fetchData } from "../../services/Api";
import Header from "../../components/Form/Header";
import MainContent from "../../components/Form/MainContent";
import Footer from "../../components/Form/Footer";
import { FaTimes, FaCartPlus } from 'react-icons/fa';
import './PizzaDetails.css';

const PizzaDetails = () => {
  const { groupId } = useParams(); 
  const location = useLocation();
  const { pizza } = location.state; 
  const navigate = useNavigate();
  const [sabores, setSabores] = useState([]);
  const [flavorsQuantity, setFlavorsQuantity] = useState(0);
  const [selectedFlavors, setSelectedFlavors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [quantity, setQuantity] = useState(1);
  const [observation, setObservation] = useState(""); // Adicionando o estado para a observação

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {    
        const result = await fetchData('/SaborDelivery/SaboresByGrupo', { grupoId: groupId });

        // Ordenar os sabores por tipoSabor
        const sortedSabores = result.sort((a, b) => a.tipoSabor - b.tipoSabor);

        setSabores(sortedSabores);
        setLoading(false);
        setFlavorsQuantity(pizza.qtdeSabor);
      } catch (error) {
        setError("Erro ao buscar sabores: " + error);
        setLoading(false);
      }
    };
    fetchDataFromAPI();
  }, [groupId, pizza.qtdeSabor]);
  

  const handleAddSabor = (sabor) => {
    console.log(flavorsQuantity);
    
    if (selectedFlavors.length < flavorsQuantity) {
      setSelectedFlavors([...selectedFlavors, sabor]);
    }
  };
  

  const handleRemoveSabor = (sabor) => {
    setSelectedFlavors(selectedFlavors.filter((f) => f.id !== sabor.id));
  };

  const totalPrice = () => {
    const flavorsPrice = selectedFlavors.length * 2;
    return flavorsPrice.toFixed(2);
  };

  const addToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    cart.push({ ...pizza, quantity, observation, selectedFlavors });
    localStorage.setItem('cart', JSON.stringify(cart));
    navigate('/cart');
  };

  const cancelAction = () => {
    navigate(-1);
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error}</p>;

  // Agrupar sabores por tipoSaborDesc
  const groupedSabores = sabores.reduce((acc, sabor) => {
    if (!acc[sabor.tipoSaborDesc]) {
      acc[sabor.tipoSaborDesc] = [];
    }
    acc[sabor.tipoSaborDesc].push(sabor);
    return acc;
  }, {});

  const buttonContainerClass = selectedFlavors.length > 0 ? "buttonContainer selected" : "buttonContainer";

  return (
    
    <>
      <Header titulo={pizza.descricao} />
      <MainContent>
        <div className="flavorsSelection">        
          <h3 className="pizzaVariation">{pizza.variacao.descricao}</h3>
          <p>Escolha os sabores:</p>
          {Object.keys(groupedSabores).map(tipoSaborDesc => (
            <div key={tipoSaborDesc}>
                <h2 className={`tipoSaborTitulo ${tipoSaborDesc.toLowerCase()}`}>{tipoSaborDesc}s</h2>
                {groupedSabores[tipoSaborDesc].map(sabor => {
                    const isSelected = selectedFlavors.some((f) => f.id === sabor.id);
                    return (
                        <div key={sabor.id} className={`saborItem ${isSelected ? "selected" : ""}`}>
                            <div className="saborDetails">
                                <h3 className="saborName">{sabor.codSabor} - {sabor.descricao}</h3>
                                <p className="saborIngredientes">{sabor.ingredientes}</p>
                            </div>
                            <div>
                                {isSelected ? (
                                    <>
                                        <button className="qtdeBotao" onClick={() => handleRemoveSabor(sabor)}>-</button>
                                        <span className="qtdeSabor">
                                            {selectedFlavors.filter(f => f.id === sabor.id).length}
                                        </span>
                                        <button className="qtdeBotao" onClick={() => handleAddSabor(sabor)}>+</button>
                                    </>
                                ) : (
                                    <button className="qtdeBotao" onClick={() => handleAddSabor(sabor)}>+</button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        ))}
        </div>
        <div className="totalPrice">
          <h3>Sabores Selecionados: {selectedFlavors.length}</h3>
        </div>

        <div className="observationContainer">
          <label className="observacaoLabel" htmlFor="observation">Observação:</label>
          <textarea
            id="observation"
            rows="3"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            placeholder="Adicione uma observação"
            className="textarea"
          />
        </div>

        <div className={buttonContainerClass}>
          <button className="cancelButton" onClick={cancelAction}>
            <FaTimes /> Cancelar
          </button>
          <button className="addToCartButton" onClick={addToCart}>
            <FaCartPlus /> Adicionar ao Carrinho
          </button>
        </div>
      </MainContent>
      
      <Footer selectedFlavors={selectedFlavors || []} />
    </>
  );
};

export default PizzaDetails;
