// src/Api.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Acessa a URL da API a partir do arquivo config.js
const apiUrl = window.APP_CONFIG?.Url_Api || 'http://default-url.com'; // Adicione uma URL padrão caso a configuração falhe

// Cria uma instância do Axios com a URL base da API
const api = axios.create({
  baseURL: apiUrl, // Define a baseURL com o valor de config.js
  // Outros parâmetros padrão, se necessário (ex.: headers)
});

// Função genérica para requisições GET
export const fetchData = async (endpoint, params = {}) => {
  try {
    console.log(params);
    const response = await api.get(endpoint, { params });
    return response.data;
  } catch (error) {
    console.error(`Erro ao obter dados de ${endpoint}:`, error);
    throw error;
  }
};

// Função genérica para requisições POST
export const postData = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`Erro ao enviar dados para ${endpoint}:`, error);
    throw error;
  }
};

// Função genérica para requisições PUT
export const putData = async (endpoint, data) => {
  try {
    const response = await api.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`Erro ao enviar dados para ${endpoint}:`, error);
    throw error;
  }
};

// Interceptores de requisição
api.interceptors.request.use(
  (config) => {
    // Adicione manipulação de token ou qualquer outra configuração de cabeçalho aqui
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptores de resposta
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 403)) {
      // Lógica de deslogar ou de redirecionar para a página de login
      localStorage.removeItem('token');
      window.location.reload();
      
      //const navigate = useNavigate();
      //navigate('/login');
    }
    return Promise.reject(error);
  }
);

// Exporta a instância do axios se precisar fazer outras requisições diretas
export default api;
