// src/pages/Usuario/CadastrarUsuario.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PerfilUsuario.css'; // Importar o arquivo CSS externo.
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import MainContent from '../../components/Form/MainContent';

//Ícones
import { IoPerson, IoLocationOutline } from "react-icons/io5";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { useAuth } from '../../contexts/AuthContext';

const PerfilUsuario = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth(); // Usando o contexto para definir autenticação

  const handleCadUsuario = () => {
    navigate('/cadastrarusuario');    
  };

  const handleCadEndereco = () => {
    navigate('/cadastrar-endereco');    
  };

  const handleMeusPedidos = () => {
    navigate('/pedidos');    
  };

  const handleLogout = () => {
    // Montar o código para logout 
    localStorage.removeItem('token');
    localStorage.removeItem('cart');
    localStorage.removeItem('totalItem');
    localStorage.removeItem('tipoEntrega');
    localStorage.removeItem('endereco');
    setIsAuthenticated(false);
    navigate('/');    
  };

  return (
    <>
      <Header titulo="Meus Dados" routeGoBack="/" />
      <MainContent>
        <div className="menu-container">
          <div className="menu-item" onClick={handleCadUsuario}>
            <IoPerson className="menu-icon"/> Meu Cadastro
          </div>
          <div className="menu-item" onClick={handleCadEndereco}>
            <IoLocationOutline className="menu-icon"/> Meu Endereço
          </div>
          <div className="menu-item" onClick={handleMeusPedidos}>
            <AiOutlineUnorderedList className="menu-icon"/> Meus Pedidos
          </div>
          <div className="menu-item" onClick={handleLogout}>
            <BiLogOut className="menu-icon"/> Desconectar
          </div>
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default PerfilUsuario;