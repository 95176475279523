import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './CadastrarEndereco.css'; // Importar o arquivo CSS externo.
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import MainContent from '../../components/Form/MainContent';
import Input from '../../components/Input/Input';
import {fetchData, postData, putData} from '../../services/Api';
import { useAuth } from '../../contexts/AuthContext';



const CadastrarEndereco = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth(); // Usando o contexto para verificar autenticação
  
  const [id, setId] = useState(0);
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const endereco = await fetchData('/Endereco'); // Faz o request para pegar o usuário
        setId(endereco.id);
        setLogradouro(endereco.logradouro);
        setNumero(endereco.numero);
        setBairro(endereco.bairro);
        setComplemento(endereco.complemento);    

      } catch (error) {
        console.error('Erro ao obter endereco:', error);
        setErrorMessage('Falha ao carregar os dados do usuário.');
      }
    };

    // Se o usuário estiver autenticado, busca os dados do usuário
    if (isAuthenticated) {
      fetchUser();
    }


    if (location.state && location.state.endereco) {
      const { id, logradouro, numero, bairro, complemento } = location.state.endereco; // Assume que o primeiro endereço deve ser editado
      setId(id);
      setLogradouro(logradouro);
      setNumero(numero);
      setBairro(bairro);
      setComplemento(complemento);  
    }
  }, [isAuthenticated, location]);

  const validateForm = () => {
    const newErrors = {};

    if (!logradouro) newErrors.logradouro = true;
    if (!numero) newErrors.numero = true;
    if (!bairro) newErrors.bairro = true;
    if (!complemento) newErrors.complemento = true;

    setErrors(newErrors);

    // Retorna true se não houver erros
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    const endereco = {
        id: id,
        //cliente: cliente, // Use clienteData em vez do estado
        cliente: { id: 1 }, //TODO: ajustar para pegar o cliente da sessão
        logradouro,
        numero,
        bairro,
        cidade: { id: 1 },//por enquanto está fixo
        complemento
    };

    try {      

        if (id) {
          const response = await putData('/Endereco', endereco);
          // Se você precisa manipular a resposta de alguma forma, adicione aqui
          console.log("Resposta da API: ", response);
        } else {
          const response = await postData('/Endereco', endereco);   
          // Se você precisa manipular a resposta de alguma forma, adicione aqui
          console.log("Resposta da API: ", response);       
        }
              
        navigate(-1);
    } catch (error) {
        console.error('Erro: ', error);
    }
  };

  return (
    <>
      <Header titulo="Cadastrar Endereço" />
      <MainContent>
        <div className="formularioContainer">
          <Input 
            type="text" 
            value={logradouro} 
            onChange={(e) => setLogradouro(e.target.value)} 
            placeholder="Rua" 
            error={errors.logradouro}
            />  
            
                              
          <Input 
            type="text" 
            value={numero} 
            onChange={(e) => setNumero(e.target.value)} 
            placeholder="Número"  
            error={errors.numero}
          />
          <Input 
            type="text" 
            value={bairro} 
            onChange={(e) => setBairro(e.target.value)} 
            placeholder="Bairro"
            error={errors.bairro}
          />
          <Input 
            type="text" 
            value={complemento} 
            onChange={(e) => setComplemento(e.target.value)} 
            placeholder="Complemento" 
          />
          <button onClick={handleSave} className="button">Salvar Endereço</button>
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default CadastrarEndereco;
